<template>
    <div class="footer-sponsors">
        <div>
            <div class="sponsors-line">
                <div>
                    <h2>Grand mécènes</h2>
                    <div class="sponsors-line__logos">
                        <img src="@/assets/img/sponsorsNew/OSRAMIS.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/LOTERIE.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/Aline.svg" alt=""> <!-- Madame Aline Foriel-Destezet--> 
                        <img src="@/assets/img/sponsorsNew/MINKOFF.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/CARIS.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/SANDOZ.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/LEENAARDS.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/BRU.svg" alt="">
                        
                        <!-- <img src="@/assets/img/WHITE__PICTET.svg" alt=""> -->
                        
                        
                        
                    </div>
                </div>
            </div>
            <div class="sponsors-line">
                <div>
                    <h2>Partenaire de saison</h2>
                    <div class="sponsors-line__logos">
                        <img src="@/assets/img/sponsorsNew/SIG.svg" alt="">
                    </div>
                </div>
                <div>
                    <h2>Partenaire de diffusion</h2>
                    <div class="sponsors-line__logos">
                        <img src="@/assets/img/sponsorsNew/RTS.svg" alt="">
                    </div>
                </div>
                <div>
                    <h2>Partenaire institutionnel</h2>
                    <div class="sponsors-line__logos">
                        <img src="@/assets/img/sponsorsNew/JTI.svg" alt="">
                    </div>
                </div>
                <!--
                <div>
                    <h2>Partenaire du Festival</h2>
                    <div class="sponsors-line__logos">
                        <img src="@/assets/img/WHITE__M3.svg" alt="">
                    </div>
                </div>
                -->
               
            </div>
            <div class="sponsors-line">
                 <div>
                    <h2>Autorités subventionnantes</h2>
                    <div class="sponsors-line__logos">
                        <img src="@/assets/img/sponsorsNew/GECANTON.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/GEVILLE.svg" alt="">
                        <img src="@/assets/img/sponsorsNew/VAUDCANTON.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
   export default {
       
    }
</script>
<style scoped lang="scss">
    .footer-sponsors {
        padding: 90px 0;
        color: white;
        background-color:#998B69;

        > div {
            max-width: 1400px;
            padding: 0 20px;
            margin: 0 auto;
        }

        img {
            max-height: 81px;
            max-width: 220px;
            margin-right: 50px;
            margin-bottom: 25px;

            @media (max-width: 991px) {
                max-height: calc((81px/3) * 2);
                max-width: calc((291px/3) * 2);
            }
        }

        @media print {
            display: none;
        }


    }

    .sponsors-line {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        

        > div {
            margin-right: 35px;
            margin-bottom: 35px;
            // width: 100%;
            flex: 1 1 auto;
        }
    }

    .sponsors-line__logos {
        display: flex;
        flex-wrap: wrap;
    }

    h2 {
        font-size: 20px;
        font-size: 2rem;
        font-size: min(max(1.4rem, 1.5vw), 2rem);
        font-size: clamp(1.4rem, 1.5vw, 2rem);
        text-align: left;
        margin: 0 0 25px 0;
        font-family: 'monotype grotesque';
    }
</style>