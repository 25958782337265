<template>
  <div class="page home">
    <div class="page__content">

        <div>
          <div class="dialog__logo-container">
            <img
              src="@/assets/img/logo-2024/osr_logo_noir_baseline.png"
              alt="OSR"
              class="dialog__logo"
            />
          </div>
          <div>
            <p>Retrouvez ici tous les programmes du soir de nos concerts vous renseignant sur
les œuvres et les artistes concernés. Disponibles une semaine à l’avance, vous pouvez les consulter
librement où que vous soyez.</p>
          </div>

          <button @click="goToProgramme()" class="btn">Voir tous les programmes</button>

          <div class="filter-select-wrapper">
            <div class="fake-dropdown">
              <span class="fake-dropdown__label">Choisir une date</span>
              <span class="fake-dropdown__arrow">↓</span>
            </div>
            <select @change="setDate($event)">
              <option value="all">Toutes les dates</option>
              <option v-for="date in dates.slice().reverse()" :key="date.id" :value="date.id">
                {{ date.nice }}
              </option>
            </select>
          </div>

          <div class="filter-select-wrapper">
            <div class="fake-dropdown">
              <span class="fake-dropdown__label">Choisir une série</span>
              <span class="fake-dropdown__arrow">↓</span>
            </div>
            <select @change="setSerie($event)">
              <option value="all">Toutes les séries</option>
              <option
                v-for="serie in series"
                :key="serie.slug"
                :value="serie.name"
              >
                {{ serie.name }}
              </option>
            </select>
          </div>

          <h2>Astuces</h2>
          <!-- <ul>
            <li>
              Ajoutez un événement à vos favoris pour y accéder plus facilement
            </li>
            <li>Mettez un concert à votre calendrier pour ne pas le rater</li>
            <li>
              Imprimez votre programme depuis votre ordinateur si vous préférez
              une version papier.
            </li>
            <li>
              Ajoutez une icône à votre téléphone pour accéder plus facilement à
              ce site.
            </li>
          </ul> -->
          <ul class="tips-list">
            <li class="tips"><img src="@/assets/img/icon-cal.svg" alt="" class="tips__img"><span class="tips__text">Ajoutez le concert à votre calendrier personnel.</span></li>
            <li class="tips"><img src="@/assets/img/icon-favorite-heart.svg" alt="" class="tips__img"><span class="tips__text">Enregistrez vos concerts favoris afin de les retrouver facilement lors de votre prochaine visite.</span></li>
            <li class="tips"><img src="@/assets/img/icon-print.svg" alt="" class="tips__img"><span class="tips__text">Imprimez le programme si vous préférez le lire sur papier.</span></li>
          </ul>
          <p>
            <strong
              >Ajoutez une icône à votre téléphone pour accéder directement au programme :</strong
            >
          </p>
          <ul>
            <li>
              Sur iPhone /Safari : cliquez sur l’icône centrale en bas de votre
              écran (carré bleu avec une flèche vers le haut), puis sur “Sur
              l’écran d’accueil”.
            </li>
            <li>
              Sur Android/Chrome : cliquez sur les 3 points en haut à droite de
              l’écran, puis sur “Ajouter à l’écran d’accueil”.
            </li>
          </ul>
          <p>
            Une nouvelle icône “OSR” figurera sur l’écran d’accueil de votre
            smartphone.
          </p>
          <div class="btn-container">
            <a
              href="https://www.osr.ch"
              class="btn"
              title="Acceuil OSR.CH"
              target="_blank"
              >Accueil OSR.CH</a
            >
          </div>
        
      </div>
      <FooterSponsors />
    </div>
  </div>
</template>
<script>
import FooterSponsors from "./FooterSponsors.vue";

export default {
  components: {
    FooterSponsors,
  },
  data() {
    return {
      series: this.$store.state.series,
      dates: this.$store.state.dates,
    };
  },
  computed: {},
  methods: {
    goToProgramme() {
    this.$store.commit("filteringEvents", {
        date: "all",
        serie: "all",
      });
      this.$router.push("/programme");
    },
    setDate($event) {
      this.$store.commit("filteringEvents", {
        date: $event.target.value,
        serie: "all",
      });
      this.$router.push("/programme");
    },
    setSerie($event) {
      this.$store.commit("filteringEvents", {
        date: "all",
        serie: $event.target.value,
      });
      this.$router.push("/programme");
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">

.home {
  background-color:#FF726D;
  color: black;
}

.page__content {
  >div:first-child {
    max-width: 750px;
  //max-height: calc(100vh - 50px);
    margin: 0 auto 30px;
    padding: 0 30px 30px;
  border: none;
  background-color:#FF726D;
  border-radius: 5px;
  }
  
}

.dialog__logo {
  display: block;
  width: 100%;
  //width: max(150px, min(20vw, 280px));
  //width: clamp(150px, 20vw, 280px);
  margin: 7.5% auto;
}

h2 {
  font-family: "monotype grotesque";
  width: 100%;
  font-size: 25px;
  font-size: 2.5rem;
  margin: 2em 0;
  text-transform: uppercase;
  text-align: center;
}

a {
  color: inherit;
}

ul,
li {
  font-size: 1.8rem;
}

strong {
  font-family: "monotype grotesque";
}



.btn-container {
  display: flex;
  justify-content: center;
}

.btn {
    height: 40px;
    height: 4rem;
    height: min(max(4rem, 5vw), 8rem);
    height: clamp(4rem, 5vw, 8rem);
    color: black;
    background-color: transparent;
    margin-right: 10px;
    border: 1px solid black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-size: 2rem;
    font-size: min(max(2rem, 5vw), 4rem);
    font-size: clamp(2rem, 5vw, 4rem);
    width: 100%;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    background-color: black;
    color:#FF726D;
  }
}

    .filter-select-wrapper {
      position: relative;
      margin-top: 20px;

    }


    .fake-dropdown {
      position: relative;
      height: 40px;
      height: 4rem;
      height: min(max(4rem, 5vw), 8rem);
      height: clamp(4rem, 5vw, 8rem);
      color: black;
      background-color: white;
      margin-right: 10px;
      padding: 0 20px;
      border: 1px solid black;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-size: 2rem;
      font-size: min(max(2rem, 5vw), 4rem);
      font-size: clamp(2rem, 5vw, 4rem);
      width: 100%;
      z-index: 1;
      pointer-events: none;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    .fake-dropdown__label {
        white-space: nowrap;
        flex: 0 1 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .fake-dropdown__arrow {
        font-family: "monotype grotesque";
        padding-left: 10px;
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .event-filter__filter {
        font-family: 'monotype grotesque';
        font-size: 1.5px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem, 2vw), 2rem);
        font-size: clamp(1.5rem, 2vw, 2rem);
    }

    .event-filter__current-filter {
        font-family: 'monotype grotesque';
        font-size: 1.5px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem, 2vw), 2rem);
        font-size: clamp(1.5rem, 2vw, 2rem);
        justify-content: center;
    }

    .event-filter__icon {
        padding-right: 5px;
        
        > span {
            position: relative;
            color: #979797;
            display: block;
            width: 20px;
            height: 1px;
            background-color: currentColor;

            &:before,
            &:after {
                content: "";
                display: block;
                height: 1px;
                position: absolute;
                background-color: currentColor;
            }

            &:before {
                top: -7px;
                left: -5px;
                width: 30px;
            }

            &:after {
                bottom: -7px;
                left: 4px;
                width: 13px;
            }            
        }

    }

    .tips-list {
      margin-bottom: 2em;
    }

    .tips {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
    }

    .tips__img {
      flex: 0 0 60px;
      max-width: 60px;
    }

    .tips__text {
      margin-left: 1em;
    }
</style>
